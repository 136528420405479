<template>
  <v-container fluid>
    <v-container
      id="regular-tables"
      fluid
      tag="section"
    >
      <!-- Date Filter -->
      <date-filter
        v-model="options.date"
        class="dateOptions"
        :validate-date-limit="false"
        cols="3"
        @key="(v) => (options.dateType = v)"
      />
      <!-- :custom="false" -->
      <!-- Date Filter -->

      <section class="mt-2">
        <div>
          <status-section
            :loading="isLoading"
            :item="items"
          />
        </div>
        <div>
          <battery-health-section
            :loading="isLoading"
            :item="items"
          />
        </div>
        <div>
          <history-section
            :loading="isLoading"
            :item="items"
            :filter-options="options"
            :date-type="options.dateType"
          />
        </div>
      </section>
    </v-container>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {
    DateFilter: () => import("@/components/base/DateDropDown.vue"),
    StatusSection: () => import("../utils/StatusSection.vue"),
    BatteryHealthSection: () => import("../utils/BatteryHealthSection.vue"),
    HistorySection: () => import("../utils/HistorySection.vue"),
  },
  data() {
    return {
      options: {},
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "batteryDiagnostics/getIsLoading",
      items: "batteryDiagnostics/getBatteryDashboard",
    }),

    vehicle_type() {
      return this.$route.query.type;
    },
  },
  watch: {
    "options.date": {
      handler: function () {
        this.fetchData();
      },
    },
  },
  // mounted() {
  //   this.fetchData();
  // },
  methods: {
    async fetchData() {
      const params = {
        ...this.options,
        imei: this.$route.params.imei,
        bmsId: this.$route.query.bmsId,
      };
      if (this.vehicle_type == "three_wheeler") {
        await this.$store
          .dispatch("batteryDiagnostics/dashboard3w", params)
          .then(() => {});
      } else {
        await this.$store
          .dispatch("batteryDiagnostics/dashboard2w", params)
          .then(() => {});
      }
    },
  },
};
</script>
<style lang="sass" scoped>
:deep .dateOptions
  .v-card
    padding: 0.5rem
    padding-bottom: 0 !important


@media only screen and (max-width: 960px)
    :deep .content-boxes
        flex-direction: column
</style>
